<template>
  <div>
    <Row
      :gutter="6"
      class="m-b-10"
    >
      <i-col span="4">
        <DatePicker
          :show-week-numbers="true"
          size="small"
          type="date"
          style="width: 100%"
          placeholder="制作日期"
          v-model="produceQuery.actionDate"
          @on-change="handleChangeDate"
        ></DatePicker>
      </i-col>
      <!-- <i-col span="4">
        <Select
          v-model="produceStatus"
          clearable
          placeholder="制作工单状态"
        >
          <Option
            v-for="item in workOrderStatus"
            :value="item.value"
            :key="item.value"
          >{{ item.name }}</Option>
        </Select>
      </i-col> -->
      <i-col span="4">
        <Input
          v-model="produceQuery.keyword"
          size="small"
          placeholder="客户/品牌/制作公司"
        />
      </i-col>
      <i-col span="4">
        <Button
          size="small"
          class="m-r-5"
          type="primary"
          icon="ios-search"
          @click="handleSeach"
        >搜索</Button>
        <Button
          type="success"
          size="small"
          @click="handelDonwload"
        >下载</Button>
      </i-col>
    </Row>

    <Table
      stripe
      max-height="720"
      size="small"
      :data="tableList"
      :columns="tableColumns"
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="produceQuery.pageSize"
        show-total
        show-elevator
        :current="produceQuery.pageNumber"
        @on-change="handlePageNumberChange"
      ></Page>
    </div>

  </div>
</template>

<script>
import em from '@/utils/enum'
import { sysMixins } from '@/assets/mixins/sys'
// import { ParseDate } from '@/utils/dateFormat'
// import { formatMspPlanStatusColor } from '@/utils/tagStatus'
import { getProducePage, getSupplyRoducePage } from '@/api/msp/produce'
import { downloadFileRequest } from '@/utils/download'

export default {
  mixins: [sysMixins],
  props: {
    serviceType: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      workOrderStatus: em.workOrderStatus,
      actionDate: '',
      produceStatus: '',
      produceQuery: {
        pageNumber: 1,
        pageSize: 15,
        actionDate: null,
        status: null,
        keyword: ''
      },
      tableList: [],
      total: 0,
      tableColumns: [
        { title: '作业日期', width: 100, key: 'actionDate' },
        { title: '客户/品牌', key: 'name' },
        { title: '制作公司', key: 'companyName' },
        {
          title: '制作清单',
          width: 150,
          render: (h, params) => {
            const spans = []
            params.row.resourceTypeStatisticList.forEach(resource => {
              spans.push(h(
                'span',
                resource.resourceTypeName + ' '
              ))
              spans.push(h(
                'span',
                {
                  attrs: {
                    style: 'font-size:12px;color:#FA8B59'
                  }
                },
                resource.quantity
              ))
              spans.push(h('br'))
            })
            return h('div', spans)
          }
        },
        // {
        //   title: '状态',
        //   width: 100,
        //   align: 'center',
        //   render: (h, data) => {
        //     const statusName = this.workOrderStatus.find(x => x.value === data.row.status).name
        //     return h('tag', { props: { color: formatMspPlanStatusColor(data.row.status) } }, statusName)
        //   }
        // },
        {
          title: '待制作/制作中/已完成',
          key: 'finishTime',
          render: (h, params) => {
            return h('span', `${params.row.pendingQuantity} / ${params.row.executingQuantity} / ${params.row.finishedQuantity}`)
          }
        },
        {
          title: '操作',
          width: 100,
          align: 'center',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  this.handleViewDetial(params.row)
                }
              }
            }, '详情')
          }
        }
      ]
    }
  },
  created () {
    // 如果存在搜索条件状态，则取之
    if (this.$store.state.produceOrder.indexSearchParams) {
      this.produceQuery = this.$store.state.produceOrder.indexSearchParams
    }
    this.loadProduceList()
    // 设置头部路由标签
    this.setLinkTagArray({ key: 'produceOrderIndex', value: '制作列表' })
    this.setLinkRouterMaps(['produceOrderIndex', () => { this.setBottomComponent('IndexTable') }])
    this.setActivedTagName('produceOrderIndex')
  },
  methods: {
    loadProduceList () {
      // this.produceQuery.actionDate = this.actionDate === '' ? null : ParseDate(this.actionDate)
      this.produceQuery.status = this.produceStatus

      // 保存搜索条件状态
      this.$store.commit('set_index_search_params', this.produceQuery)

      if (this.serviceType === 1) {
        getProducePage(this.produceQuery).then(res => {
          this.tableList = res.list
          this.total = res.totalRow
        })
      } else {
        getSupplyRoducePage(this.produceQuery).then(res => {
          this.tableList = res.list
          this.total = res.totalRow
        })
      }
    },
    handlePageNumberChange (page) {
      this.produceQuery.pageNumber = page
      this.loadProduceList()
    },
    handleSeach () {
      this.produceQuery.pageNumber = 1
      this.loadProduceList()
    },
    handleChangeDate (date) {
      this.produceQuery.actionDate = date
    },
    handleViewDetial (produceBean) {
      this.$store.commit('set_produce_info', produceBean)
      this.setBottomComponent('Detail')

      // 设置头部路由标签
      this.setLinkTagArray({ key: 'produceOrderDetail', value: '制作详情' })
      this.setLinkRouterMaps(['produceOrderDetail', () => { this.setBottomComponent('Detail') }])
      this.setActivedTagName('produceOrderDetail')
    },
    handelDonwload () {
      var downloadActionDate = ''
      this.$Modal.confirm({
        width: '400px',
        render: (h) => {
          return h('DatePicker', {
            props: {
              value: downloadActionDate,
              type: 'daterange',
              placeholder: '选择日期范围'
            },
            style: { width: '100%' },
            on: {
              input: (val) => {
                downloadActionDate = val
              }
            }
          })
        },
        onOk: () => {
          if (downloadActionDate[0] === '') {
            this.$Notice.error({ desc: '请选择日期范围' })
            return false
          }
          const sDate = new Date(downloadActionDate[0])
          const eDate = new Date(downloadActionDate[1])

          const startDate = `${sDate.getFullYear()}-${(sDate.getMonth() + 1).toString().padStart(2, '0')}-${sDate.getDate().toString().padStart(2, '0')}`
          const endDate = `${eDate.getFullYear()}-${(eDate.getMonth() + 1).toString().padStart(2, '0')}-${eDate.getDate().toString().padStart(2, '0')}`

          const filename = '制作工单(' + startDate + '至' + endDate + ').xlsx'
          const params = {
            startDate: startDate,
            endDate: endDate,
            type: 3
          }
          switch (this.serviceType) {
            case 1:
              // 非供应商
              downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/msppreport/downloadplanexcel', params, filename)
              break
            case 2:
              // 供应商
              downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/msppreport/downloadsupplierplanexcel', params, filename)
              break
          }
        }
      })
    }
  }
}
</script>
